<template>
  <b-card
      title="分组设备"
  >
    <div class="custom-search">
      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="2">
          <h1>{{ row.pathStr }}</h1>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :sort-options="{
        enabled: false,
      }"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:queryParams.pageSize
      }"
        theme="my-theme"
    >
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'deviceStatus'" class="text-nowrap">
          <span>{{ formatterDeviceStatus(props.row.deviceStatus) }}</span>
        </span>

        <span v-if="props.column.field === 'noticeStatus'" class="text-nowrap">
          <span>{{ formatterDeviceNoticeStatus(props.row.noticeStatus) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'option'">
          <b-button
              v-if="props.row.canHandleWarning"
              :key="props.row.id"
              v-ripple.400="'rgba(22, 155, 213, 0.15)'"
              variant="info"
              class="btn-sm mr-50 mb-50"
              badge="4"
              v-b-modal.modal-relation
              @click="handleButtonClick(props.row)"
          >
              解除警报
          </b-button>
        </span>
        <span v-else-if="props.column.field != 'deviceStatus'">
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="tCenter">
          <b-pagination
              :value="queryParams.currentPage"
              :total-rows="queryParams.total"
              :per-page="queryParams.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>
    <b-modal
        id="modal-relation"
        title="解除警报"
        centered
        ok-only
        ok-title="确定"
        scrollable
        @ok="handleEvent"
    >
      <div >
        <div>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="请输入解除备注" v-model="handleInfo.handleNote">
          </div>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活'
        }
      ],
      roleFilter: null,
      columns: [
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        },
        {
          label: '警报状态',
          field: 'noticeStatus',
        },
        {
          label: '操作',
          field: 'option',
        },
      ],
      rows: [],
      searchTerm: '',
      dir: 'ltr',

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        tenantGroupId: null,
      },

      row: this.$route.params.row,

      deviceNoticeOptions: [
        {
          value: 0,
          label: '无'
        },
        {
          value: 1,
          label: '警报'
        },
      ],

      handleInfo: {
        handleNote: '',
        deviceConfigId: null,
      },

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

  },
  created() {
    this.getTableList();
    this.deviceStatusOptionsFormat();
  },
  methods: {
    getTableList() {
      request.post('tob/tenant/tenantGroupView/pageGroupViewDeviceByTenantGroupId', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        tenantGroupId: this.row.tenantGroupId,
      }).then(res => {
        if (res.data.success) {
          this.rows = res.data.data.data
          this.queryParams.total = res.data.data.count
        }
      })
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getTableList()
    },

    //格式化后端返回的事件处理状态枚举
    deviceStatusOptionsFormat() {
      request.get('tob/bDevice/getDeviceStatusEnums', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.deviceStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.deviceStatusOptions.push(enumObject);
          }
        }
      })
    },

    formatterDeviceStatus(val) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },

    formatterDeviceNoticeStatus(noticeStatus){
      for (let item of this.deviceNoticeOptions) {
        if (item.value === noticeStatus) {
          return item.label
        }
      }
      return noticeStatus;
    },

    handleEvent(params) {
      if (this.handleInfo.deviceConfigId==null||this.handleInfo.deviceConfigId<=0){
        this.$message.success('设备错误')
      }

      params.preventDefault(); //禁止关闭

      request.post('tob/bEventRecord/updateByHandleEventRecord', {
        deviceConfigId: this.handleInfo.deviceConfigId,
        handleNote: this.handleInfo.handleNote,
      }).then(res => {
        if (res.data.success) {
          this.$message.success('解除成功')

          this.$nextTick(() => { //关闭
            this.$bvModal.hide('modal-relation')
          })

          this.getTableList()
        }
      })
    },

    handleButtonClick(row) {
      this.handleInfo.deviceConfigId = row.deviceConfigId ;
      this.handleInfo.handleNote = null;
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
